<script>
import Layout from "../../layouts/main";
import ShopCategoriesView from "@/components/product/shop-categories-view.vue";

export default {
  components: {
    ShopCategoriesView,
    Layout
  }
};
</script>

<template>
  <Layout>
    <shop-categories-view />
  </Layout>
</template>